import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieListeTirages from "../../../composants/layout/fr/LayoutGalerieListeTirages"

import { obtenirImages, obtenirPage } from "../../../js/client-es/utils"
import fragmentsImages from "../../../js/client-es/fragments/fragments-images"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    pageVenteTiragesAuroresBoreales: allPagesCompilationYaml(
      filter: { code: { eq: "venteTiragesNebuleuses" } }
    ) {
      ...FragmentPageYaml
    }
    imagesPaysage: allImagesCompilationYaml(
      filter: {
        code: {
          in: [
            "ngc3372Carene"
            "ngc2070Tarentule"
            "ngc6188MainsDeDieu"
            "ngc7000"
            "ic5146Cocon"
            "ic1848Ame"
            "ic4628Crevette"
            "ngc3576StatueLiberte"
            "ngc281Pacman"
            "ic4592ChevalAustral"
            "b33"
          ]
        }
      }
    ) {
      ...FragmentImagePaysage
    }

    imagesPortrait: allImagesCompilationYaml(
      filter: { code: { in: ["orion"] } }
    ) {
      ...FragmentImagePortrait
    }
  }
`

export default function TiragesNebuleuses() {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "pageVenteTiragesAuroresBoreales")
  const images = obtenirImages(
    resultatsRequete,
    "imagesPaysage",
    "imagesPortrait"
  )

  return <LayoutGalerieListeTirages page={page} images={images} />
}
